import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import logo from "../../assets/images/logo-sm.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CreateLabTestPage = () => {
  document.title = "Create Lab Test";

  const navigate = useNavigate();
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [title, setTitle] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title !== "") {
      try {
        setLoading(true);

        const response = await axios.post(
          `${apiRoute}/api/admin/createLabTest`,
          { title: title }
        );
        if (response.status === 201) {
          setToastMessage(response.message);
          setTimeout(() => {
            navigate("/lab-test-list");
          }, 1500);
        }
      } catch (err) {
        const errSplit = err.split(" ");
        const statusCode = errSplit[errSplit.length - 1];
        if (statusCode === "409") {
          setToastMessage("This Lab Test already exits");
        }
      }
      setLoading(false);
      settoast1(true);
    } else {
      setToastMessage("Please Enter Some Title");
    }
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Laboratory" breadcrumbItem="Create Test" />
          <Row>
            <Col>
              <Card className="px-5">
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Title :
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Title *"
                          required
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <div className="col-10 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          disabled={loading}
                        >
                          Create Lab Test
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateLabTestPage;
