/* BANNERS */
export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL";

/* DOCTORS */
export const GET_DOCTORS = "GET_DOCTORS";
export const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS";
export const GET_DOCTORS_FAIL = "GET_DOCTORS_FAIL";

/* PATIENTS */
export const GET_PATIENT = "GET_PATIENT";
export const GET_PATIENT_SUCCESS = "GET_PATIENT_SUCCESS";
export const GET_PATIENT_FAIL = "GET_PATIENT_FAIL";

/* AMBULANCE */
export const GET_AMBULANCE_ORG = "GET_AMBULANCE_ORG";
export const GET_AMBULANCE_ORG_SUCCESS = "GET_AMBULANCE_ORG_SUCCESS";
export const GET_AMBULANCE_ORG_FAIL = "GET_AMBULANCE_ORG_FAIL";

/* LABORATORY */
export const GET_LABORATORY_ORG = "GET_LABORATORY_ORG";
export const GET_LABORATORY_ORG_SUCCESS = "GET_LABORATORY_ORG_SUCCESS";
export const GET_LABORATORY_ORG_FAIL = "GET_LABORATORY_ORG_FAIL";

/* LABORATORY */
export const GET_PHARMACY_ORG = "GET_PHARMACY_ORG";
export const GET_PHARMACY_ORG_SUCCESS = "GET_PHARMACY_ORG_SUCCESS";
export const GET_PHARMACY_ORG_FAIL = "GET_PHARMACY_ORG_FAIL";
