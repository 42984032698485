import React from "react";

import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";

import { BasicTable } from "./datatableCom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

const LaboratoryListPage = () => {
  document.title = "Laboratory Organizations List";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Laboratory"
            breadcrumbItem="Organization List"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    All Laboratory Organizations
                  </h5>
                </CardHeader>
                <CardBody>
                  <BasicTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LaboratoryListPage;
