import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";
import axios from "axios";

import logo from "../../../assets/images/logo-sm.png";

const BasicTable = () => {
  const [labTestApiData, setLabTestApiData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [labTestId, setLabTestId] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  // get lab test data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${apiRoute}/api/admin/allLabTests`);
      setLabTestApiData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // get api data
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete lab test
  const handleDeleteLabTest = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/deleteLabTest/${labTestId}`
      );
      if (data?.status === 200) {
        fetchData();
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Title</span>,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setLabTestId(cell._id);
                setIsModalOpen(!isModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-danger remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                Delete
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = labTestApiData.map((labData, index) => {
    const obj = {
      _id: labData._id,
      srNo: index + 1,
      title: labData.title,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <DataTable columns={columns} data={data2} pagination />
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Are You Sure to Delete This Lab Test?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDeleteLabTest}
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export { BasicTable };
