import {
  GET_BANNERS,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  GET_DOCTORS,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_FAIL,
  GET_PATIENT,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAIL,
  GET_AMBULANCE_ORG,
  GET_AMBULANCE_ORG_SUCCESS,
  GET_AMBULANCE_ORG_FAIL,
  GET_LABORATORY_ORG,
  GET_LABORATORY_ORG_SUCCESS,
  GET_LABORATORY_ORG_FAIL,
  GET_PHARMACY_ORG,
  GET_PHARMACY_ORG_SUCCESS,
  GET_PHARMACY_ORG_FAIL,
} from "./actionTypes";

export const getBanners = () => ({
  type: GET_BANNERS,
});

export const getBannersSuccess = (banners) => ({
  type: GET_BANNERS_SUCCESS,
  payload: banners,
});

export const getBannersFail = (error) => ({
  type: GET_BANNERS_FAIL,
  payload: error,
});

export const getDoctors = () => ({
  type: GET_DOCTORS,
});

export const getDoctorsSuccess = (doctors) => ({
  type: GET_DOCTORS_SUCCESS,
  payload: doctors,
});

export const getDoctorsFail = (error) => ({
  type: GET_DOCTORS_FAIL,
  payload: error,
});

export const getPatients = () => ({
  type: GET_PATIENT,
});

export const getPatientsSuccess = (patients) => ({
  type: GET_PATIENT_SUCCESS,
  payload: patients,
});

export const getPatientsFail = (error) => ({
  type: GET_PATIENT_FAIL,
  payload: error,
});

export const getAmbulanceOrg = () => ({
  type: GET_AMBULANCE_ORG,
});

export const getAmbulanceOrgSuccess = (data) => ({
  type: GET_AMBULANCE_ORG_SUCCESS,
  payload: data,
});

export const getAmbulanceOrgFail = (error) => ({
  type: GET_AMBULANCE_ORG_FAIL,
  payload: error,
});

export const getLaboratoryOrg = () => ({
  type: GET_LABORATORY_ORG,
});

export const getLaboratoryOrgSuccess = (data) => ({
  type: GET_LABORATORY_ORG_SUCCESS,
  payload: data,
});

export const getLaboratoryOrgFail = (error) => ({
  type: GET_LABORATORY_ORG_FAIL,
  payload: error,
});

export const getPharmacyOrg = () => ({
  type: GET_PHARMACY_ORG,
});

export const getPharmacyOrgSuccess = (data) => ({
  type: GET_PHARMACY_ORG_SUCCESS,
  payload: data,
});

export const getPharmacyOrgFail = (error) => ({
  type: GET_PHARMACY_ORG_FAIL,
  payload: error,
});
