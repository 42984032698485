import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { getPatients } from "../../../store/actions";

import logo from "../../../assets/images/logo-sm.png";

const BasicTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  const dispatch = useDispatch();

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    dispatch(getPatients());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      patients: state.patients,
      // patientError: state.patientError,
    })
  );

  const { patients } = useSelector(apisDataList);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete user
  const handleDeleteUser = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/deleteUser/${patientId}`
      );
      if (data?.status === 200) {
        dispatch(getPatients());
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setPatientId(cell._id);
                setIsViewModalOpen(!isViewModalOpen);
              }}
            >
              <button
                className="btn btn-sm remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div className="remove">
              <button
                className="btn btn-sm btn-danger remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                Remove
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = patients.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.fullName,
      email: data.email,
      phone: data.phoneNo,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <DataTable columns={columns} data={data2} pagination />
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Are You Sure to Delete This Banner?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDeleteUser}
          >
            Yes
          </button>
        </div>
      </Modal>

      {/* view patient */}
      {isViewModalOpen && (
        <PatientView
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          apiRoute={apiRoute}
          patientId={patientId}
        />
      )}
    </>
  );
};

const PatientView = ({
  isViewModalOpen,
  setIsViewModalOpen,
  apiRoute,
  patientId,
}) => {
  const [doctorData, setDoctorData] = useState({});

  useEffect(() => {
    const fetchSignleOrg = async () => {
      try {
        const data = await axios.get(
          `${apiRoute}/api/admin/getPatient/${patientId}`
        );
        if (data?.status === 200) {
          setDoctorData(data.data);
        }
      } catch (err) {
        if (err) {
        }
      }
    };

    fetchSignleOrg();
  }, [apiRoute, patientId]);

  return (
    <Modal
      isOpen={isViewModalOpen}
      toggle={() => {
        setIsViewModalOpen(!isViewModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Patient Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsViewModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label
            htmlFor="recipient-name"
            className="col-form-label d-flex gap-1 flex-column"
          >
            <span>Name : {doctorData[0]?.fullName}</span>
            {/* <br /> */}
            <span>Email : {doctorData[0]?.email}</span>
            {/* <br /> */}
            <span>Phone Number : {doctorData[0]?.phoneNo}</span>
            {/* <br /> */}
            <span>Gender : {doctorData[0]?.gender}</span>
            <span>
              Appointment Booked for : {doctorData[0]?.appointmentFor}
            </span>
            <span>Appointment Booked date : {doctorData[0]?.bookedDate}</span>
            <span>
              Appointment Start & End Time : {doctorData[0]?.startTime} -{" "}
              {doctorData[0]?.endTime}
            </span>
            <span>Appointment Current Status : {doctorData[0]?.status}</span>
          </label>
        </div>
      </div>
    </Modal>
  );
};

export { BasicTable };
