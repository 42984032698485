import React from "react";

import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";

import { BasicTable } from "./DataTables/datatableCom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

const CategoryListPage = () => {
  document.title = "Categories List";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Category"
            breadcrumbItem="Categories List"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">All Categories List</h5>
                </CardHeader>
                <CardBody>
                  <BasicTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoryListPage;
