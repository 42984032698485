import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { getBanners } from "../../../store/actions";

import logo from "../../../assets/images/logo-sm.png";

const BasicTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const [activeStatus, setActiveStatus] = useState();
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  const dispatch = useDispatch();

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    dispatch(getBanners());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      banners: state.banners,
      // bannerError: state.bannerError,
    })
  );

  const { banners } = useSelector(apisDataList);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete banner
  const handleDeleteBanner = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/deleteBanner/${bannerId}`
      );
      if (data?.status === 200) {
        dispatch(getBanners());
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsModalOpen(false);
  };

  // handle update banner
  const handleUpdateBanner = async () => {
    try {
      const data = await axios.put(
        `${apiRoute}/api/admin/updateBanner/${bannerId}`
      );
      if (data?.status === 200) {
        dispatch(getBanners());
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsEditModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Title</span>,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Description</span>,
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Type</span>,
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Assigned To</span>,
      sortable: true,
      selector: (cell) => {
        return (
          <React.Fragment>
            <div className="">
              <img src={cell.assigned} alt="" className="table-images" />
            </div>
          </React.Fragment>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.status) {
          case "In-Active":
            return <span className="badge bg-danger"> {cell.status} </span>;
          case "Active":
            return <span className="badge bg-success"> {cell.status} </span>;
          default:
            return <span className="badge bg-info"> {cell.status} </span>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (cell) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="edit-item-btn"
                onClick={() => {
                  setBannerId(cell._id);
                  setActiveStatus(cell.status);
                  setIsEditModalOpen(!isEditModalOpen);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              <DropdownItem
                className="remove-item-btn"
                onClick={() => {
                  setBannerId(cell._id);
                  setIsModalOpen(!isModalOpen);
                }}
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const data2 = banners.map((bannerData, index) => {
    const obj = {
      _id: bannerData._id,
      srNo: index + 1,
      title: bannerData.title,
      description: bannerData.description,
      type: bannerData.type,
      assigned: bannerData.imgUrl,
      status: bannerData.active ? "Active" : "In-Active",
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <DataTable columns={columns} data={data2} pagination />
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Are You Sure to Delete This Banner?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDeleteBanner}
          >
            Yes
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isEditModalOpen}
        toggle={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            You Only Active and de-Active the Banners?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              Currently this banner is <u>{activeStatus}</u>.
              <br />
              Are You Sure to{" "}
              <u>{activeStatus === "Active" ? "In-Active" : "Active"}</u> This
              Banner?
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdateBanner}
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export { BasicTable };
