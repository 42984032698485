import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import logo from "../../../assets/images/logo-sm.png";
import { Link } from "react-router-dom";

import { getDoctors } from "../../../store/actions";

const BasicTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [docId, setDocId] = useState("");
  const [activeApproval, setActiveApproval] = useState();
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const dispatch = useDispatch();

  // get api data
  useEffect(() => {
    dispatch(getDoctors());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      doctors: state.doctors,
      // doctorError: state.doctorError,
    })
  );

  const { doctors } = useSelector(apisDataList);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete user
  const handleDeleteUser = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/deleteUser/${docId}`
      );
      if (data?.status === 200) {
        dispatch(getDoctors());
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsModalOpen(false);
  };

  // handle update user
  const handleUpdateUser = async () => {
    try {
      const data = await axios.put(
        `${apiRoute}/api/admin/updateDoctorApproval/${docId}`
      );
      if (data?.status === 200) {
        dispatch(getDoctors());
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsEditModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Approved</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.approved) {
          case "No":
            return <span className="badge bg-danger"> {cell.approved} </span>;
          case "Yes":
            return <span className="badge bg-success"> {cell.approved} </span>;
          default:
            return <span className="badge bg-info"> {cell.approved} </span>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setDocId(cell._id);
                setIsViewModalOpen(!isViewModalOpen);
              }}
            >
              <button
                className="btn btn-sm remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                setDocId(cell._id);
                setActiveApproval(cell.approval);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-success edit-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                Edit
              </button>
            </div>
            <div className="remove">
              <button
                className="btn btn-sm btn-danger remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                Remove
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = doctors.map((data, index) => {
    const obj = {
      _id: data.doctor_data._id,
      srNo: index + 1,
      name: data.doctor_data.fullName,
      email: data.doctor_data.email,
      phone: data.doctor_data.phoneNo,
      verified: data.doctor_data.Verified ? "Yes" : "No",
      approved: data.approval ? "Yes" : "No",
      approval: data.approval,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <DataTable columns={columns} data={data2} pagination />
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Are You Sure to Delete This Banner?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDeleteUser}
          >
            Yes
          </button>
        </div>
      </Modal>

      {/* view doctor */}
      {isViewModalOpen && (
        <DoctorView
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          apiRoute={apiRoute}
          docId={docId}
        />
      )}

      {/* // update doctor approval */}
      <Modal
        isOpen={isEditModalOpen}
        toggle={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            You Only Approved & Un-Approved this Doctor?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              Currently this Doctor is{" "}
              <u>{activeApproval ? "Approved" : "Not Approved"} By Admin</u>.
              <br />
              Are You Sure to{" "}
              <u>{activeApproval ? "Un-Approved" : "Approved"}</u> This Doctor?
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdateUser}
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

const DoctorView = ({
  isViewModalOpen,
  setIsViewModalOpen,
  apiRoute,
  docId,
}) => {
  const [doctorData, setDoctorData] = useState({});

  useEffect(() => {
    const fetchSignleOrg = async () => {
      try {
        const data = await axios.get(
          `${apiRoute}/api/admin/getDoctor/${docId}`
        );
        if (data?.status === 200) {
          setDoctorData(data.data[0]);
        }
      } catch (err) {
        if (err) {
        }
      }
    };

    fetchSignleOrg();
  }, [apiRoute, docId]);

  return (
    <Modal
      isOpen={isViewModalOpen}
      toggle={() => {
        setIsViewModalOpen(!isViewModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Organization Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsViewModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="recipient-name" className="col-form-label">
            Name : {doctorData?.doctor_data?.fullName}
            <br />
            Email : {doctorData?.doctor_data?.email}
            <br />
            Phone Number : {doctorData?.doctor_data?.phoneNo}
            <br />
            Gender : {doctorData?.doctor_data?.gender}
            <br />
            Doctor Qualification : {doctorData?.doctorQualification}
            <br />
            Doctor Description : {doctorData?.doctorDescription}
            <br />
            Doctor Category : {doctorData?.doctorCategory}
            <br />
            Doctor Certificates :{" "}
            {doctorData?.doctorCertificates?.map((cert) => (
              <div key={cert.imageId}>
                View Certificate {cert.imageId}{" "}
                <Link to={cert.certificateImageUrl} target="_black">
                  Click
                </Link>
              </div>
            ))}
          </label>
        </div>
      </div>
    </Modal>
  );
};

export { BasicTable };
