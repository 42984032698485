const backURL = process.env.REACT_APP_LIVESERVERURL;

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const USER_LOGIN = `${backURL}/api/admin/login`;
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";
export const PUT_EDIT_PROFILE = `${backURL}/api/admin/updatePassword`;

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

export const GET_BANNERS = `${backURL}/api/admin/allbanners`;
export const GET_DOCTORS = `${backURL}/api/admin/allDoctorUsers`;
export const GET_PATIENTS = `${backURL}/api/admin/allPatientUsers`;
export const GET_AMBULANCE_ORG = `${backURL}/api/ambulance/organization/all`;
export const GET_LABORATORY_ORG = `${backURL}/api/laboratory/auth/allLabUsers`;
export const GET_PHARMACY_ORG = `${backURL}/api/pharmacy/auth/allPharmacyUsers`;
